import React from "react"
import { graphql, Link } from "gatsby"
import ReactMarkdown from "react-markdown"

import {
  CardSection,
  FaqSection,
  IllustratedOptionsSection,
  GoogleReviews,
} from "../components/Sections"
import {
  BoilerBlob,
  BulletListWithIcon,
  ButtonWithIcon,
  Card,
  Layout,
  Pill,
  IllustratedOption,
  ImageSection,
  Seo,
} from "../components/Shared"
import { smoothScrollToElementWithId } from "../utilities"

import HomeIllustration from "../images/illustrations/services/landlord-services.svg"
import BoilerIllustration from "../images/illustrations/services/boiler-services.svg"
import BookIllustration from "../images/illustrations/services/book-installation.svg"
import RepairsIllustration from "../images/illustrations/services/repairs.svg"

const NewBoilers = ({ data }) => (
  <Layout>
    <Seo
      url={data.site && data.site.siteMetadata.url + "/new-boilers"}
      title={data.headerAndSeo.seo && data.headerAndSeo.seo.title}
      description={data.headerAndSeo.seo.metaDescription.metaDescription}
      image={
        data.headerAndSeo.seo &&
        data.headerAndSeo.seo.image &&
        data.headerAndSeo.seo.image.file &&
        data.headerAndSeo.seo.image.file.url
      }
    />
    <header className="wrapper-width grid-col-2 header">
      <div className="header-right">
        <BoilerBlob
          photo={data.getANewBoilerSection.image.fluid}
          photoDesc={data.getANewBoilerSection.image.title}
        />
      </div>
      <div className="header-left">
        <h1 className="heading-extra-large">{data.headerAndSeo.headerTitle}</h1>
        <ReactMarkdown
          source={data.headerAndSeo.headerParagraph.headerParagraph}
        />
        <BulletListWithIcon bullets={data.headerAndSeo.headerBullets} />
        <div className="header-buttons">
          <ButtonWithIcon
            icon="arrow-down"
            onClick={() => smoothScrollToElementWithId("how-it-works")}
          >
            How it works
          </ButtonWithIcon>
        </div>
      </div>
    </header>
    <IllustratedOptionsSection
      heading="How it works"
      textDirection="centered"
      id="how-it-works"
    >
      <IllustratedOption
        heading="Tell us about your home"
        illustration={<HomeIllustration />}
        number={1}
      >
        <p>
          Answer a few questions so we can recommend the perfect new boiler for
          your home.
        </p>
      </IllustratedOption>
      <IllustratedOption
        heading="Choose your boiler"
        illustration={<BoilerIllustration />}
        number={2}
      >
        <p>
          Pick your new boiler from our range of award-winning brands. Tailored
          to your answers.
        </p>
      </IllustratedOption>
      <IllustratedOption
        heading="Book your installation"
        illustration={<BookIllustration />}
        number={3}
      >
        <p>
          Secure a date and get your new boiler installed exactly when you need
          it.
        </p>
      </IllustratedOption>
      <IllustratedOption
        heading="We install for you"
        illustration={<RepairsIllustration />}
        number={4}
      >
        <p>
          We'll replace your boiler in under 2 working days, recycling your old
          boiler (safely).
        </p>
      </IllustratedOption>
      <div className="illustrated-options-section-button">
        <Link to="/quote" className="button-primary">
          Get a new boiler
        </Link>
      </div>
    </IllustratedOptionsSection>
    <GoogleReviews backgroundColour="white" />
    <CardSection
      heading="Boiler finance options"
      paragraph="We want to make sure everyone who needs a new boiler gets a new boiler. Choose one of the options below when you book your installation."
    >
      <Card preHeading="Pay by card" heading="One-off payment">
        <Pill text="Fastest" />
        <BulletListWithIcon
          bullets={[
            "No loan required",
            "Everything paid up-front",
            "Outstanding service",
            "Best boilers on the market",
          ]}
        />
        <p>
          Pay for the full cost of your new boiler when you book your
          installation.
        </p>
      </Card>
      <Card preHeading="50% up-front" heading="12-month loan">
        <Pill text="Interest-free" />
        <BulletListWithIcon
          bullets={[
            "0% APR Representative",
            "50% up-front required",
            "Outstanding service",
            "Best boilers on the market",
          ]}
        />
        <p>
          Pay for 50% of your boiler when you book your installation, and pay
          for the remaining 50% with 12 monthly instalments.
        </p>
      </Card>
      <Card preHeading="Pay monthly" heading="24 - 120 month loan">
        <Pill text="Most affordable" />
        <BulletListWithIcon
          bullets={[
            "9.9% APR Representative",
            "£250 deposit required",
            "Outstanding service",
            "Best boilers on the market",
          ]}
        />
        <p>
          Pay a £250 deposit when you book your installation, and pay for the
          rest of your boiler with 24 - 120 monthly instalments.
        </p>
      </Card>
      <div className="card-section-button">
        <Link to="/quote" className="button-primary">
          Get a new boiler
        </Link>
      </div>
    </CardSection>
    <ImageSection
      image={data.cleaningServicesSection.image.fluid}
      imageDescription={data.cleaningServicesSection.image.title}
      direction="text-right"
      blobColour="blue"
    >
      <h2 className="heading-large">{data.cleaningServicesSection.heading}</h2>
      <p>{data.cleaningServicesSection.paragraph.paragraph}</p>
    </ImageSection>
    {data.headerAndSeo.faqs && <FaqSection faqs={data.headerAndSeo.faqs} />}
  </Layout>
)

export default NewBoilers

export const pageQuery = graphql`
  query getANewBoilerContent {
    site {
      siteMetadata {
        url
      }
    }
    headerAndSeo: contentfulPages(page: { eq: "Boiler Landing" }) {
      contentful_id
      page
      seo {
        image {
          file {
            url
          }
        }
        metaDescription {
          metaDescription
        }
        title
      }
      headerTitle
      headerParagraph {
        headerParagraph
      }
      headerBullets
      faqs {
        answer {
          answer
        }
        question
        id
      }
    }
    getANewBoilerSection: contentfulPageSections(
      contentful_id: { eq: "7nA633vl1X7eb3M2uHYUSZ" }
    ) {
      image {
        title
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
    cleaningServicesSection: contentfulPageSections(
      contentful_id: { eq: "5mxWGh9uiQGAdbINTmCl4Y" }
    ) {
      contentful_id
      heading
      image {
        title
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      bullets
      paragraph {
        paragraph
      }
    }
  }
`
